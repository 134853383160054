.footer{
    padding-top: 70px;
    padding-bottom: 30px;
    text-align: center; /*cambio actual*/
}
.footer-logo img{
    width: 50%;
    margin-bottom: 1rem;
}
.footer .logo p{
    color: var(--text-color)
}
.social__links span a {
    text-decoration: none;
    color: var(--heading-color);
    font-size: 1.2rem;
}
.footer__link-title {
    color: var(--heading-color);
    margin-bottom: 1rem;
}
.footer__quick-links {
    text-align: center; /* Centra los enlaces en el medio en dispositivos móviles */
}
.footer__quick-links li a{
    text-decoration: none;
    color: var(--text-color);
    font-size: 1.1rem;
}
.footer__quick-links h6 span i{
    color: var(--secondary-color);
    font-size: 1.1rem;
}
.copyright{
    color: var(--text-color);
    font-size: 1rem;
}
.social-icons {
  display: flex;
  justify-content: center;
  list-style: none;
  height: fit-content;
  padding: 1rem;
  overflow: hidden;
}

.social-icons span {
  margin: 3px 3px; /* Cambiado de .social-icons li a .social-icons span */
}

.social-icons span a {
  font-size: 30px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  position: relative;
  transition: 0.5s ease;
  text-shadow: 0 0 30px;
}

.social-icons span a::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(1px) saturate(180%);
  -webkit-backdrop-filter: blur(6px) saturate(180%);
  background-color: rgba(17, 25, 40, 0);
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.social-icons span a:hover {
  text-shadow: 0 0 0px;
}
.social-icons span:nth-child(1) a {
  color: lawngreen; /* Color para el primer enlace (WhatsApp) */
}

.social-icons span:nth-child(4) a {
  color: red; /* Color para el segundo enlace (Facebook) */
}

.social-icons span:nth-child(3) a {
  color: #D43089; /* Color para el tercer enlace (Instagram) */
}

.social-icons span:nth-child(2) a {
  color: #3b5998
}

  /* Media query para laptops y pantallas grandes */
@media (min-width: 1025px) {
    /* Puedes realizar ajustes adicionales según tus necesidades para pantallas grandes */
}

/* Media query para celulares */
@media (max-width: 576px) {
    .footer-logo img {
      width: 80%; /* Reducir el ancho del logo en dispositivos móviles */
      margin-bottom: 1rem;
    }
  
    .footer .logo p {
      font-size: 18px; /* Ajustar el tamaño de fuente en dispositivos móviles si es necesario */
      color: var(--text-color);
    }
  
    .social__links span a {
      text-decoration: none;
      color: var(--heading-color);
      font-size: 1.2rem;
    }
  
    .footer__link-title {
      color: var(--heading-color);
      margin-bottom: 1rem;
      font-size: 20px; /* Ajustar el tamaño de fuente del título en dispositivos móviles si es necesario */
    }
  
    .footer__quick-links {
      text-align: center; /* Centra los enlaces en el medio en dispositivos móviles */
    }
  
    .footer__quick-links li a {
      text-decoration: none;
      color: var(--text-color);
      font-size: 1rem; /* Ajustar el tamaño de fuente de los enlaces en dispositivos móviles si es necesario */
    }
  
    .footer__quick-links h6 span i {
      color: var(--secondary-color);
      font-size: 1rem; /* Ajustar el tamaño de fuente de los íconos de contacto en dispositivos móviles si es necesario */
    }
  
    .copyright {
      color: var(--text-color);
      font-size: 1rem;
    }
}

/* Media query para tablets (como iPads) */
@media (min-width: 769px) and (max-width: 1024px) {
    .footer-logo img {
      width: 70%; /* Reducir el ancho del logo en tablets */
      margin-bottom: 1rem;
    }
  
    .footer .logo p {
      font-size: 20px; /* Ajustar el tamaño de fuente en tablets si es necesario */
      color: var(--text-color);
    }
  
    .social__links span a {
      text-decoration: none;
      color: var(--heading-color);
      font-size: 1.2rem;
    }
  
    .footer__link-title {
      color: var(--heading-color);
      margin-bottom: 1rem;
      font-size: 22px; /* Ajustar el tamaño de fuente del título en tablets si es necesario */
    }
  
    .footer__quick-links {
      display: flex; /* Mostrar los enlaces en la misma línea */
      justify-content: space-between; /* Espacio uniforme entre los enlaces */
      text-align: left; /* Alineación a la izquierda para el bloque de enlaces */
      flex-wrap: wrap; /* Permitir que los elementos se envuelvan en múltiples líneas */
    }
  
    .footer__quick-links li {
      width: 48%; /* Distribuir los enlaces en dos columnas */
      margin-bottom: 1rem; /* Espacio entre los elementos en la misma línea */
    }
  
    .footer__quick-links li a {
      text-decoration: none;
      color: var(--text-color);
      font-size: 1rem; /* Ajustar el tamaño de fuente de los enlaces en tablets si es necesario */
    }
  
    .footer__quick-links h6 span i {
      color: var(--secondary-color);
      font-size: 1rem; /* Ajustar el tamaño de fuente de los íconos de contacto en tablets si es necesario */
    }
  
    .copyright {
      color: var(--text-color);
      font-size: 1rem;
    }
    
    /* Mover las imágenes de social__links debajo de la imagen del logo */
    .social__links {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;
    }
}