.about-container {
    padding: 80px 0;
    background-color: #f8f8f8;
    text-align:left;
}

.about-heading {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.about-text {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #666;
    margin-bottom: 30px;
}

.about-button {
    border-radius: 30px;
    border: none !important;
    text-decoration: none;
    background: var(--secondary-color) !important;
    cursor: pointer;
    color: #ffffff;
    padding: 12rem 13rem !important;
}

/* Team Turims */
.about-container {
    padding: 80px 0;
    background-color: #f8f8f8;
    text-align: left;
}

.about-heading {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.about-text {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #666;
    margin-bottom: 30px;
}

.about-button {
    border-radius:15px;
    border: none !important;
    text-decoration: none;
    background: var(--secondary-color) !important;
    cursor: pointer;
    color: #ffffff;
    padding: 0.7rem 0.5rem !important;

}

.about-info-container {
    padding: 60px 0;
    background-color: #f8f8f8;
    text-align: left;
}

.about-info {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    background-color: #f8efed;
}

.about-info h4 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 10px;
}

.about-info p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #666;
    margin-bottom: 10px;
}
.info__title-South{
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #362e32;
}
.datos__title{
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #666;
}
p span{
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}
/* Certificates */
.certificate-info {
    display: grid;
    padding: 0;
    padding-top: 10px;
    box-sizing: border-box;
    row-gap: 15px;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    align-items: flex-end;
}
.cert-imgs {
    display: grid;
    grid-auto-flow: column;
    column-gap: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 10px;
}
.cert-imgs > img {
    height: auto;
    width: 200px;
    border-radius: 50%;
    transition: all .3s ease;
    place-self: center;
}
.cert-imgs > img:hover {
    border-radius: 4px;
    opacity: .9;
}
.tour-container > *:nth-child(1) {
    color: #666666;
}
.tour-container > *:nth-child(1) .article-title, .tour-container > *:nth-child(1) h1, .title-black {
    color: #000000 !important;
}
.tour-container > .white {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 0 15px 0px 0px;
    overflow: hidden;
}
.tour-container > .white .tour-image {
    border-radius: 0;
}
.certificate-info > h3 {
    color: #000000;
    text-align: center;
}
.bg-imgs {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    
}
.bg-imgs > * {
    width: auto;
    height: calc(100% + 50px);
    position: relative;
    opacity: 1;
    transition: all .5s cubic-bezier(1, -0.18, 0.97, 1.57);
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
}
.certificate-info > *:not(.bg-imgs) {
    z-index: 1;
}
.certificate-info > p {
    padding: 10px;
    margin: 0;
    width: auto;
    background-color: rgba(0,0,0,.5);
    color: #ffffff;
    position: absolute;
}
.certs {
    display: grid;
    grid-auto-flow: column;
    overflow-x: auto;
    column-gap: 10px;
    place-content: center;
    background: none;
    position: relative;
}

.cert-imgs > .certs {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    transition: all 0.3s ease;
}

.cert-imgs > .certs > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease;
}

.cert-imgs > .certs:hover {
    width: auto;
    border-radius: 4px;
    overflow: hidden;
}
